import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withHeader from '../components/withHeader';
import CreateUser from '../components/CreateUser';
import EditUser from '../components/EditUser';
import CreateCustomerOrSite from '../components/CreateCustomerOrSite';
import { setCustomers } from '../actions/customersActions';
import CRUDService from '../services/CRUDService';

function CreateAccount() {
  const dispatch = useDispatch();
  const customers = useSelector(state => state.customersReducer.customers);

  const [activeTab, setActiveTab] = useState('createUser');

  useEffect(() => {
    if (customers.length === 0) {
      CRUDService.getCustomers()
        .then(data => {
          dispatch(setCustomers(data));
        });
    }
  }, []);

  return (
    <article className='flex justify-center items-center text-gray-700'>
      <nav style={{ height: '84vh' }} className="flex flex-col self-start ml-4 pr-4 border-r-2 border-gray-500">
        <button
          onClick={() => setActiveTab('createUser')}
          className={`pb-2 px-2 block hover:text-red-800 focus:outline-none 
            ${activeTab === 'createUser' && 'text-red-800 border-b-2 border-red-800'}`}
        >
          Create New User Account
        </button>
        <button
          onClick={() => setActiveTab('editUser')}
          className={`py-2 px-2 block hover:text-red-800 focus:outline-none 
            ${activeTab === 'editUser' && 'text-red-800 border-b-2 border-red-800'}`}
        >
          Edit a User
        </button>
        <button
          onClick={() => setActiveTab('createCustomer')}
          className={`py-2 px-2 block hover:text-red-800 focus:outline-none 
            ${activeTab === 'createCustomer' && 'text-red-800 border-b-2 border-red-800'}`}
        >
          Create New Customer/Site
        </button>
      </nav>
      {activeTab === 'createUser' && customers.length && <CreateUser customers={customers} />}
      {activeTab === 'editUser' && customers.length && <EditUser customers={customers} />}
      {activeTab === 'createCustomer' && <CreateCustomerOrSite customers={customers} />}
    </article>
  );
}

const wrappedComponent = withHeader(CreateAccount);
export { wrappedComponent as CreateAccount };