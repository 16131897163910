import { SAVE_LATEST_PICTURES, CLEAR_STATE } from '../actions/actionTypes';

const initialState = {
  latestPictures: []
}

export const latestPicturesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LATEST_PICTURES:
      return { ...state, latestPictures: action.payload };
    case CLEAR_STATE:
      return { ...initialState };
    default:
      return state;
  }
}