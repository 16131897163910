import { SAVE_LATEST_FILES, CLEAR_STATE } from '../actions/actionTypes';

const initialState = {
  latestFiles: []
}

export const latestFilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LATEST_FILES:
      return { ...state, latestFiles: action.payload };
    case CLEAR_STATE:
      return { ...initialState };
    default:
      return state;
  }
}