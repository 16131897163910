import { UPDATE_FILE_LOAD_PROGRESS, CLEAR_FILE_LOAD_PROGRESS } from './actionTypes';

export const updateFileLoadProgress = (progress) => {
  return {
    type: UPDATE_FILE_LOAD_PROGRESS,
    payload: progress
  }
}

export const clearFileLoadProgress = () => {
  return {
    type: CLEAR_FILE_LOAD_PROGRESS
  }
}