import { UPDATE_FILE_LOAD_PROGRESS, CLEAR_FILE_LOAD_PROGRESS } from '../actions/actionTypes';

const initialState = {
  progress: 0
}

export const uploadFileReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FILE_LOAD_PROGRESS:
      return { ...state, progress: action.payload };
    case CLEAR_FILE_LOAD_PROGRESS:
      return { ...state, progress: 0 };
    default:
      return state;
  }
}