import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveLatestPictures } from '../actions/latestPicturesActions';
import withHeader from '../components/withHeader';
import CRUDService from '../services/CRUDService';
import { getToken } from '../services/getToken';
import { formatDate } from '../services/formatDate';
import { ReactComponent as FileIcon } from '../assets/file.svg';
import { ReactComponent as DownloadIcon } from '../assets/download.svg';
import { styles } from '../styles';
import { BASE_URL } from '../constants';

function LatestPictureUploads() {
  const dispatch = useDispatch();
  const latestPictures = useSelector(state => state.latestPicturesReducer.latestPictures);

  const token = getToken();

  const [search, setSearch] = useState('');
  const [filteredPictures, setFilteredPictures] = useState(latestPictures);

  const searchFile = () => {
    if (search !== '') {
      setFilteredPictures(latestPictures
        .filter(item => item.name.toLowerCase().includes(search.toLowerCase())
          || item.description.toLowerCase().includes(search.toLowerCase())
        )
      )
    }
    else {
      setFilteredPictures(latestPictures);
    }
  }

  useEffect(() => {
    CRUDService.getLastFiles('pictures')
      .then(data => {
        dispatch(saveLatestPictures(data));
        setFilteredPictures(data);
      })
  }, []);

  return (
    <article className='flex flex-col items-center'>
      <div className='w-11/12 mb-4'>
        <input
          type="text"
          className={styles.searchInput}
          placeholder="Search Pictures"
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
          onKeyPress={(ev) => (ev.key === 'Enter') ? searchFile(search) : null}
        />
        <button
          className={styles.searchButton}
          onClick={(ev) => searchFile(search)}
        >
          GO
        </button>
      </div>
      <table className='w-11/12 text-center text-gray-700'>
        <thead className='flex w-full'>
          <tr className='flex w-full border-solid border-2'>
            <th className='w-1/20'></th>
            <th className='w-4/20'>Site Name</th>
            <th className='w-6/20'>File Description</th>
            <th className='w-3/20'>Uploaded By</th>
            <th className='w-2/20'>Size</th>
            <th className='w-3/20'>Date</th>
            <th className='w-1/20'></th>
          </tr>
        </thead>
        <tbody className='flex flex-col items-center overflow-y-scroll w-full' style={{ height: '60vh' }}>
          {filteredPictures.map((item, index) => {
            return (
              <tr className='flex items-center w-full my-2 border-solid border-b-2' file-id={item.id} key={index}>
                <td className='w-1/20' align='center'><FileIcon /></td>
                <td className='w-4/20 cursor-pointer overflow-x-hidden' onClick={(ev) => {
                  CRUDService.getFile(ev.currentTarget.parentNode.getAttribute('file-id'));
                }}>
                  {item.name}
                </td>
                <td className='w-6/20 overflow-x-hidden'>{item.description}</td>
                <td className='w-3/20'>{item.uploadedBy.username}</td>
                <td className='w-2/20'>{Math.round(item.size / 1000)} kb</td>
                <td className='w-3/20'>{formatDate(item.updatedAt)}</td>
                <td className='w-1/20 px-4 cursor-pointer' align='center'>
                  <a name={item.name} href={`${BASE_URL}/file/${item.id}?token=${token}`} download>
                    <DownloadIcon />
                  </a>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </article>
  );
}

const wrappedComponent = withHeader(LatestPictureUploads);
export { wrappedComponent as LatestPictureUploads };