import Cookies from 'js-cookie';
import { BASE_URL } from '../constants';

export const resetPassword = (data) => {
  return fetch(BASE_URL + '/reset-password', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(resp => resp.json())
    .then(data => {
      if (data.accessToken) {
        Cookies.set('token', {
          accessToken: data.accessToken,
          expires: data.expiresIn,
          role: data.user.role
        })
        return data;
      }
      else {
        return null;
      }
    })
}