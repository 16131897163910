import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setDashboard } from '../actions/dashboardActions';
import Folders from '../components/Folders';
import FilesForQDDFolders from '../components/FilesForQDDFolders';
import FilesForDISSFolders from '../components/FilesForDISSFolders';
import withHeader from '../components/withHeader';
import UploadFile from '../components/UploadFile';
import CRUDService from '../services/CRUDService';
import { getRole } from '../services/getRole';

function Dashboard() {
  const dispatch = useDispatch();
  const folders = useSelector(state => state.dashboardReducer.folders);

  const { customerId, dashboardId } = useParams();

  const [customer, setCustomer] = useState('');
  const [activeFolderId, setActiveFolderId] = useState(0);
  const [activeFolderIndex, setActiveFolderIndex] = useState(0);

  const role = getRole();

  const changeActiveFolder = (id, index) => {
    setActiveFolderId(id);
    setActiveFolderIndex(index);
  }

  useEffect(() => {
    CRUDService.getCustomer(customerId)
      .then(data => setCustomer(data));

    CRUDService.getSite(dashboardId)
      .then(data => {
        const sortedData = data.folders.sort((a, b) => (a.index > b.index) ? 1 : -1);
        dispatch(setDashboard(sortedData));
      });
  }, []);

  return (
    <article className='flex flex-col items-center text-gray-700'>
      {customer &&
        <h2 className='w-11/12 mb-3'>
          <Link
            className='underline cursor-pointer'
            to={`/customers/${customerId}`}
          >
            {customer.name}
          </Link>
          <span> - </span>
          <span className={activeFolderId !== 0 ? 'underline cursor-pointer' : ""} onClick={() => setActiveFolderId(0)}>
            {customer.sites.filter(item => item.id === Number(dashboardId))[0].name}
          </span>
          {activeFolderId !== 0 &&
            <>
              <span> - </span>
              <span>{folders.filter(item => item.id === activeFolderId)[0].name}</span>
            </>
          }
        </h2>
      }
      {(activeFolderId === 0)
        ? <Folders
            data={folders}
            activeFolderId={activeFolderId}
            changeActiveFolder={changeActiveFolder}
        />
        : (activeFolderIndex === 3 || activeFolderIndex === 4 || activeFolderIndex === 5)
          ? <FilesForQDDFolders
              data={folders.filter(item => item.id === activeFolderId)[0].files}
              activeFolderId={activeFolderId}
              activeFolderIndex={activeFolderIndex}
              role={role}
            />
          : <FilesForDISSFolders
              data={folders.filter(item => item.id === activeFolderId)[0].files}
              activeFolderId={activeFolderId}
              activeFolderIndex={activeFolderIndex}
              role={role}
            />
      }
      {role !== 'CUSTOMER_EMPLOYEE' && folders.length && activeFolderId === 0 &&
        <UploadFile folders={folders} />
      }
    </article>
  )
}

const wrappedComponent = withHeader(Dashboard);
export { wrappedComponent as Dashboard };