import { SET_CUSTOMERS, ADD_NEW_CUSTOMER, ADD_SITE_TO_CUSTOMER, CLEAR_STATE } from '../actions/actionTypes';

const initialState = {
  customers: []
}

export const customersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMERS:
      return { ...state, customers: action.payload };
    case ADD_NEW_CUSTOMER:
      return { ...state, customers: [action.payload, ...state.customers] };
    case ADD_SITE_TO_CUSTOMER:
      return { 
        ...state, 
        customers: state.customers.map(item => (item.id === action.payload.id) ? action.payload : item ) 
      };
    case CLEAR_STATE:
      return { ...initialState };
    default:
      return state;
  }
}