export const SET_CUSTOMERS = 'SET_CUSTOMERS';
export const ADD_NEW_CUSTOMER = 'ADD_NEW_CUSTOMER';
export const ADD_SITE_TO_CUSTOMER = 'ADD_SITE_TO_CUSTOMER';

export const SET_DASHBOARD = 'SET_DASHBOARD';
export const ADD_FILE = 'ADD_FILE ';
export const EDIT_FILE = 'EDIT_FILE';
export const DELETE_FILE = 'DELETE_FILE';

export const SAVE_LATEST_PICTURES = 'SAVE_LATEST_PICTURES';
export const SAVE_LATEST_FILES = 'SAVE_LATEST_FILES';

export const UPDATE_FILE_LOAD_PROGRESS = 'UPDATE_FILE_LOAD_PROGRESS';
export const CLEAR_FILE_LOAD_PROGRESS = 'CLEAR_FILE_LOAD_PROGRESS';

export const CLEAR_STATE = 'CLEAR_STATE';