export const styles = {
  loginInput: 'shadow appearance-none border rounded w-64 py-2 px-3 mb-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline',
  loginButton: 'flex justify-center items-center focus:outline-none bg-red-800 hover:bg-red-900 text-white w-64 font-bold py-2 px-4 mb-4 rounded-lg',
  logoutButton: 'inline-block px-2 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-red-900 hover:bg-white mt-4 lg:mt-0 cursor-pointer focus:outline-none',
  searchInput: 'shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mr-2',
  searchButton: 'bg-red-800 hover:bg-red-900 rounded-lg text-white font-bold py-2 px-4 focus:outline-none',
  passwordInput: 'shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mx-2',
  passwordButton: 'bg-red-800 hover:bg-red-900 rounded-lg text-white font-bold py-2 px-4 focus:outline-none',
  newAccountInput: 'shadow appearance-none border rounded w-64 py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline m-2',
  editAccountInput: 'shadow appearance-none border rounded w-56 py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline m-2',
  commonSelect: 'block appearance-none bg-gray-300 border border-gray-200 text-gray-700 w-64 py-3 px-4 pr-8 my-2 rounded-lg leading-tight focus:outline-none focus:border-gray-500',
  newAccountButton: 'w-64 bg-red-800 hover:bg-red-900 rounded-lg text-white font-bold py-2 px-4 my-3 focus:outline-none',
  uploadFileInput: 'shadow appearance-none w-72 border rounded py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3 mr-2',
  uploadFileInputForQDD: 'shadow appearance-none w-40 border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-3 mr-2',
  uploadFileSelect: 'block appearance-none w-72 bg-gray-300 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded-lg leading-tight mb-4 focus:outline-none focus:border-gray-500',
  selectFileLabel: 'w-64 flex flex-col justify-center items-center px-4 py-4 text-blue-500 hover:text-white hover:bg-blue-500 rounded-lg shadow-lg tracking-wide uppercase border border-gray-300 cursor-pointer',
  uploadFileButton: 'w-64 bg-red-800 hover:bg-red-900 rounded-lg text-white font-bold py-2 px-4 my-4 focus:outline-none',
  arrowDown: 'pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700',
}