import React from 'react';
import { useDispatch } from 'react-redux';
import { clearState } from '../actions/clearState';
import { NavLink, Link, useHistory } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getRole } from '../services/getRole';
import { styles } from '../styles';
import logo from '../assets/logo.png';

const withHeader = (WrappedComponent) => {
  return (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const role = getRole();

    return (
      <>
        <nav className="flex items-center justify-between font-bold flex-wrap bg-red-900 mb-4 p-3">
          <Link to='/customers'>
            <img className="mr-3 w-36" src={logo} alt='Logo' />
          </Link>
          <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
            <div className={`flex lg:flex-grow`}>
              <NavLink
                to='/customers'
                className='text-white hover:text-yellow-300 pr-2'
                activeClassName='text-yellow-300'
              >
                {role === 'CUSTOMER_EMPLOYEE'
                  ? 'Dashboards'
                  : 'Customers'
                }
              </NavLink>
              {(role === 'ROOT') &&
                <span className='text-white'>|</span>
              }
              {role === 'ROOT' &&
                <NavLink
                  exact
                  to='/dashboard/new'
                  className='text-white hover:text-yellow-300 px-2'
                  activeClassName='text-yellow-300'
                >
                  Create New Account
                </NavLink>
              }
              {(role !== 'CUSTOMER_EMPLOYEE') &&
                <span className='text-white'>|</span>
              }
              {(role !== 'CUSTOMER_EMPLOYEE') &&
                <NavLink
                  exact
                  to='/latest-pictures'
                  className='text-white hover:text-yellow-300 px-2'
                  activeClassName='text-yellow-300'
                >
                  Latest Picture Uploads
              </NavLink>
              }
              {(role === 'ROOT') &&
                <span className='text-white'>|</span>
              }
              {(role === 'ROOT') &&
                <NavLink
                  exact
                  to='/latest-files'
                  className='text-white hover:text-yellow-300 px-2'
                  activeClassName='text-yellow-300'
                >
                  Latest File Uploads
              </NavLink>
              }
            </div>
            <div>
              <button
                className={styles.logoutButton}
                onClick={() => {
                  Cookies.remove('token');
                  dispatch(clearState());
                  history.push('/');
                }}
              >
                LOGOUT
                </button>
            </div>
          </div>
        </nav>
        <WrappedComponent {...props} />
      </>
    );
  }
}

export default withHeader;
