import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { editFile, deleteFile } from '../actions/dashboardActions';
import CRUDService from '../services/CRUDService';
import { formatDate } from '../services/formatDate';
import { getToken } from '../services/getToken';
import { ReactComponent as FileIcon } from '../assets/file.svg';
import { ReactComponent as PenIcon } from '../assets/pen.svg';
import { ReactComponent as SaveIcon } from '../assets/save.svg';
import { ReactComponent as TrashIcon } from '../assets/trash.svg';
import { ReactComponent as DownloadIcon } from '../assets/download.svg';
import { ReactComponent as SortIcon } from '../assets/sort.svg';
import { BASE_URL } from '../constants';
import DeletionItemPopup from "./DeletionItemPopUp";

/**
 * Component for rendering info about files in the next folders: Drawings, Instructions/Orders, Site Accounts, Site Photographs
 */
function FilesForDISSFolders({ data, activeFolderId, activeFolderIndex, role }) {
  const dispatch = useDispatch();
  const [files, setFiles] = useState(data);
  const [deleteConfirm, setDeleteConfirm] = useState('')
  const [targetId, setTargetId] = useState(0)
  const [openModal, setOpenModal] = useState(false)
  const [sortByName, setSortByName] = useState('asc');
  const [sortByDescription, setSortByDescription] = useState('asc');
  const [sortByCustomerRef, setSortByCustomerRef] = useState('asc');
  const [sortByWfRef, setSortByWfRef] = useState('asc');
  const [sortByDateReceived, setSortByDateReceived] = useState('asc');
  const [sortByDate, setSortByDate] = useState('asc');

  const [isEditFile, setIsEditFile] = useState(0);

  const token = getToken();

  const handleEditClick = (data) => {
    let updatedFile;

    if (activeFolderIndex === 1) {
      updatedFile = {
        customerRef: data[1].innerText,
        wfRef: data[2].innerText,
        description: data[3].innerText
      }
    }
    else {
      updatedFile = {
        name: data[1].innerText,
        description: data[2].innerText
      }
    }

    CRUDService.updateFile(isEditFile, updatedFile)
      .then(resp => resp.ok
        ? dispatch(editFile({ ...updatedFile, folderId: activeFolderId, fileId: isEditFile }))
        : null
      );

    setIsEditFile(0);
  }
  const handleTargetId = (id) =>{
    setOpenModal(true)
    setTargetId(id)
  }
  const onHandleChangeInput = (text) => {
    setDeleteConfirm(text)
  }
  const handleDeleteClick = (fileId) => {
    if(deleteConfirm === 'Delete'){
      CRUDService.deleteFile(fileId)
          .then(resp => resp.ok
              ? dispatch(deleteFile({ folderId: activeFolderId, fileId }))
              : null
          )
      setOpenModal(false)
      setDeleteConfirm('')
    }
  }

  const sort = (sortBy) => {
    if (sortBy === 'name') {
      setFiles(items => {
        if (sortByName === 'asc') {
          setSortByName('desc');
          return items.sort((a, b) => a[sortBy] >= b[sortBy] ? -1 : 1);
        }
        else {
          setSortByName('asc');
          return items.sort((a, b) => a[sortBy] > b[sortBy] ? 1 : -1);
        }
      })
    }
    else if (sortBy === 'description') {
      setFiles(items => {
        if (sortByDescription === 'asc') {
          setSortByDescription('desc');
          return items.sort((a, b) => a[sortBy] >= b[sortBy] ? -1 : 1);
        }
        else {
          setSortByDescription('asc');
          return items.sort((a, b) => a[sortBy] > b[sortBy] ? 1 : -1);
        }
      })
    }
    else if (sortBy === 'customerRef') {
      setFiles(items => {
        if (sortByCustomerRef === 'asc') {
          setSortByCustomerRef('desc');
          return items.sort((a, b) => a[sortBy] >= b[sortBy] ? -1 : 1);
        }
        else {
          setSortByCustomerRef('asc');
          return items.sort((a, b) => a[sortBy] > b[sortBy] ? 1 : -1);
        }
      })
    }
    else if (sortBy === 'wfRef') {
      setFiles(items => {
        if (sortByWfRef === 'asc') {
          setSortByWfRef('desc');
          return items.sort((a, b) => a[sortBy] >= b[sortBy] ? -1 : 1);
        }
        else {
          setSortByWfRef('asc');
          return items.sort((a, b) => a[sortBy] > b[sortBy] ? 1 : -1);
        }
      })
    }
    else if (sortBy === 'dateReceived') {
      setFiles(items => {
        if (sortByDateReceived === 'asc') {
          setSortByDateReceived('desc');
          return items.sort((a, b) => Date.parse(b.lastUpdate) - Date.parse(a.lastUpdate));
        }
        else {
          setSortByDateReceived('asc');
          return items.sort((a, b) => Date.parse(a.lastUpdate) - Date.parse(b.lastUpdate));
        }
      })
    }
    else if (sortBy === 'lastUpdate' || sortBy === 'updatedAt') {
      setFiles(items => {
        if (sortByDate=== 'asc') {
          setSortByDate('desc');
          return items.sort((a, b) => Date.parse(b[sortBy]) - Date.parse(a[sortBy]));
        }
        else {
          setSortByDate('asc');
          return items.sort((a, b) => Date.parse(a[sortBy]) - Date.parse(b[sortBy]));
        }
      })
    }
  }

  useEffect(() => {
    setFiles(data);
  }, [data]);

  return (
    <>
      <DeletionItemPopup
          filesData={files}
          openModal={openModal}
          setOpenModal={setOpenModal}
          deleteConfirm={deleteConfirm}
          onHandleChangeInput={onHandleChangeInput}
          handleDeleteClick={handleDeleteClick}
          targetId={targetId} />
      <table className='w-11/12 text-center text-gray-700'>
        <thead className='flex w-full'>
          <tr className='flex w-full justify-center items-center border-solid border-2'>
            <th className={activeFolderIndex === 1 ? 'w-1/20' : 'w-2/20'}></th>
            <th className='w-3/20 cursor-pointer flex justify-center items-center'
              onClick={() => sort(activeFolderIndex === 1 ? 'customerRef' : 'name')}
            >
              <span className='mr-1'>
                {(activeFolderIndex === 1 || activeFolderIndex === 3 || activeFolderIndex === 4 || activeFolderIndex === 5)
                    ? "Customer Ref." : "File Name"}
              </span>
              <SortIcon />
            </th>
            {activeFolderIndex === 1 &&
              <th className='w-2/20 cursor-pointer flex justify-center items-center'
                onClick={() => sort('wfRef')}
              >
                <span className='mr-1'>WF Ref.</span>
                <SortIcon />
              </th>
            }
            <th className='w-4/20 cursor-pointer flex justify-center items-center'
              onClick={() => sort('description')}
            >
              <span className='mr-1'>File Description</span>
              <SortIcon />
            </th>
            <th className='w-2/20'>Size</th>
            {activeFolderIndex === 1 &&
              <th className='w-2/20 cursor-pointer flex justify-center items-center'
                onClick={() => sort('dateReceived')}
              >
                <span className='mr-1'>Date Received</span>
                <SortIcon />
              </th>
            }
            <th className='w-3/20 cursor-pointer flex justify-center items-center'
              onClick={() => activeFolderIndex === 2 ? sort('lastUpdate') : sort('updatedAt')}
            >
              {activeFolderIndex === 1
                ? <>
                    <span className='mr-1'>
                      <span>Date Details</span><br />
                      <span>Last Amended</span>
                    </span>
                    <SortIcon />
                  </>
                : <>
                    <span className='mr-1'>Date</span>
                    <SortIcon />
                  </>
              }
            </th>
            {role === 'ROOT'
              && <th className={activeFolderIndex === 1 ? 'w-1/20' : 'w-2/20'}>Edit</th>
            }
            {role === 'ROOT'
              && <th className={activeFolderIndex === 1 ? 'w-1/20' : 'w-2/20'}>Delete</th>
            }
            <th className={activeFolderIndex === 1 ? 'w-1/20' : 'w-2/20'}></th>
          </tr>
        </thead>
        <tbody className='flex flex-col items-center overflow-y-scroll w-full'
          style={{ height: '60vh' }}
        >
          {files.map((item, index) => {
            return (
              <tr className='flex justify-center items-center w-full mb-2 border-solid border-b-2'
                file-id={item.id} key={index}
              >
                <td className={activeFolderIndex === 1 ? 'w-1/20' : 'w-2/20'} align='center'>
                  <FileIcon />
                </td>
                <td
                  className={`w-3/20 focus:outline-none ${(isEditFile === item.id)
                    ? 'bg-gray-200 border-gray-300'
                    : ''
                    }`}
                  contentEditable={isEditFile === item.id}
                >
                  {activeFolderIndex === 1 ? item.customerRef : item.name}
                </td>
                {activeFolderIndex === 1 &&
                  <td
                    className={`w-2/20 focus:outline-none ${(isEditFile === item.id) ? 'bg-gray-200' : ''}`}
                    align='center'
                    contentEditable={isEditFile === item.id}
                  >
                    {item.wfRef}
                  </td>
                }
                <td
                  className={`w-4/20 focus:outline-none ${(isEditFile === item.id) ? 'bg-gray-200' : ''}`}
                  align='center'
                  contentEditable={isEditFile === item.id}
                >
                  {item.description}
                </td>
                <td className='w-2/20'>{Math.round(item.size / 1000)} kb</td>
                {activeFolderIndex === 1 &&
                  <td className='w-2/20'>{formatDate(item.lastUpdate)}</td>
                }
                <td className='w-3/20'>
                  {activeFolderIndex === 2
                    ? formatDate(item.lastUpdate)
                    : formatDate(item.updatedAt)}
                </td>
                {role === 'ROOT' &&
                  <td className={`${activeFolderIndex === 1 ? 'w-1/20' : 'w-2/20'} cursor-pointer`}
                    align='center'
                    onClick={(ev) => isEditFile === item.id
                      ? handleEditClick(ev.currentTarget.parentNode.childNodes)
                      : setIsEditFile(Number(ev.currentTarget.parentNode.getAttribute('file-id')))
                  }>
                    {(isEditFile === item.id) ? <SaveIcon /> : <PenIcon />}
                  </td>
                }
                {role === 'ROOT' &&
                  <td className={`${activeFolderIndex === 1 ? 'w-1/20' : 'w-2/20'} cursor-pointer`}
                    align='center'
                    onClick={(ev) => handleTargetId(ev.currentTarget.parentNode.getAttribute('file-id'))
                  }>
                    <TrashIcon />
                  </td>
                }
                <td className={`${activeFolderIndex === 1 ? 'w-1/20' : 'w-2/20'} cursor-pointer`}
                  align='center'
                >
                  <a href={`${BASE_URL}/file/${item.id}?token=${token}`} download>
                    <DownloadIcon />
                  </a>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default FilesForDISSFolders;
