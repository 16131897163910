import React from 'react';
import axios from 'axios';
import { getToken } from './getToken';
import { getRole } from './getRole';
import { BASE_URL } from '../constants';

class CRUDService extends React.Component {
  static getUsers = (role) => {
    const token = getToken();
    const api = `/user${role ? `?role=${role}` : ''}`;

    return fetch(BASE_URL + api, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(resp => resp.json());
  }

  static getPermissions = (id) => {
    const token = getToken();
    const api = `/user/${id}`;

    return fetch(BASE_URL + api, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(resp => resp.json());
  }

  static postEmployeeWithPermissions = (data) => {
    const token = getToken();
    const api = `/user`;

    return fetch(BASE_URL + api, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(resp => resp.json());
  }

  static updateEmployeeWithPermissions = (id, data) => {
    const token = getToken();
    const api = `/user/${id}`;

    return fetch(BASE_URL + api, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(resp => resp.json());
  }

  static getCustomers = () => {
    const token = getToken();

    return fetch(BASE_URL + '/customer?includeSites=true', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(resp => resp.json());
  }

  static getCustomer = async (id) => {
    const token = getToken();
    const param = (getRole() === 'CUSTOMER') ? 'me' : id;

    return await fetch(BASE_URL + `/customer/${param}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(resp => resp.json());
  }

  static postCustomer = (data) => {
    const token = getToken();

    return fetch(BASE_URL + '/customer', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(resp => resp.json())
  }

  static deleteCustomer = (id) => {
    const token = getToken();

    return fetch(BASE_URL + `/customer/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  }

  static getSite = (id) => {
    const token = getToken();
    const api = `/site/${id}`;

    return fetch(BASE_URL + api, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(resp => resp.json());
  }

  static postSite = ({ name, customerId }) => {
    const token = getToken();

    const api = `/customer/${customerId}/site`;

    return fetch(BASE_URL + api, {
      method: 'POST',
      body: JSON.stringify({ name }),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(resp => resp.json())
  }

  static deleteSite = (id) => {
    const token = getToken();

    return fetch(BASE_URL + `/site/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });
  }

  static getFile = (id, name) => {
    const token = getToken();

    return fetch(BASE_URL + `/file/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(resp => resp.blob())
      .then(blob => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        (name)
          ? link.download = name
          : link.target = '_blank';
        link.click();
      })
  }

  static postFile = ({ 
    customerRef,
    wfRef,
    quoteNo,
    dayworkSheetNo,
    jobNo,
    filename,
    description,
    lastUpdate,
    file,
    folderId,
    handleUploadProgress
  }) => {
    const token = getToken();

    const data = new FormData();

    data.append("file", file);
    data.append("customerRef", customerRef);
    data.append("quoteNo", quoteNo);
    data.append("dayworkSheetNo", dayworkSheetNo);
    data.append("jobNo", jobNo);
    data.append("wfRef", wfRef);
    data.append("filename", filename);
    data.append("description", description);
    lastUpdate && data.append("lastUpdate", lastUpdate);
    data.append("folderId", Number(folderId));

    return axios.post(BASE_URL + '/file',
      data, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer ' + token
      },
      withCredentials: true,
      onUploadProgress: progressEvent => handleUploadProgress(progressEvent)
    })
  }

  static updateFile = (id, data) => {
    const token = getToken();

    return fetch(BASE_URL + `/file/${id}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    });
  }

  static deleteFile = (id) => {
    const token = getToken();

    return fetch(BASE_URL + `/file/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    });
  }

  static getLastFiles = (type) => {
    const token = getToken();

    return fetch(BASE_URL + `/file${(type === 'pictures') ? '/picture-uploads' : '/file-uploads'}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    })
      .then(resp => resp.json());
  }
}

export default CRUDService;