import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Alert from './Alert';
import CRUDService from '../services/CRUDService';
import { addNewCustomer, addSiteToCustomer } from '../actions/customersActions';
import { ReactComponent as ArrowDownIcon } from '../assets/arrow-down.svg';
import { styles } from '../styles';

function CreateCustomerOrSite({ customers }) {
  const dispatch = useDispatch();

  const [customerOrSite, setCustomerOrSite] = useState('customer');
  const [name, setName] = useState('');
  const [customer, setCustomer] = useState(customers[0]?.id);

  const [alertState, setAlertState] = useState('');
  const closeAlert = () => setAlertState(false);

  const setInitilalState = () => {
    setName('');
    setCustomer(customers[0].id);
  }
  const handleClickCreate = () => {
    if (name) {
      if (customerOrSite === 'customer') {
        CRUDService.postCustomer({ name })
          .then(data => {
            if (!data.statusCode) {
              setAlertState('Success!');
              setInitilalState();
              dispatch(addNewCustomer({ 
                id: data.id, 
                name: data.name, 
                updatedAt: data.updatedAt,
                sites: []
              }))
            }
          })
          .catch(error => {
            setAlertState('Failure!');
            setInitilalState();
          })
      }
      else {
        CRUDService.postSite({
          name,
          customerId: customer
        })
          .then(data => {
            if (!data.statusCode) {
              dispatch(addSiteToCustomer(data));
              setAlertState('Success!');
              setInitilalState();
            }
          })
          .catch(error => {
            setAlertState('Failure!');
            setInitilalState();
          })
      }
    }
  }

  return (
    <article className="flex flex-col items-center w-full mb-20">
      <div className='flex flex-row'>
        <div className='flex flex-col items-center mt-6 mr-4'>
          <label className="mb-9">Creating a</label>
          <label className={`${customerOrSite === 'site' && 'mb-9'}`} htmlFor="name">Name</label>
          {customerOrSite === 'site' && <label>Add to Customer</label>}
        </div>
        <div className='flex flex-col items-center'>
          <div className='relative'>
            <select
              className={styles.commonSelect + ' cursor-pointer'}
              value={customerOrSite}
              onChange={ev => setCustomerOrSite(ev.target.value)}
            >
              <option value='customer'>Customer</option>
              <option value='site'>Site</option>
            </select>
            <div className={styles.arrowDown}>
              <ArrowDownIcon />
            </div>
          </div>
          <input
            id="name"
            type="text"
            placeholder='Enter Name'
            className={styles.newAccountInput}
            value={name}
            onChange={(ev) => setName(ev.target.value)}
          />
          {customerOrSite === 'site' &&
            <div className='relative'>
              <select
                className={styles.commonSelect + ' w-80 truncate cursor-pointer'}
                value={customer}
                onChange={ev => setCustomer(ev.target.value)}
                disabled={(customerOrSite === 'site') ? false : true}
              >
                {customers.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
              </select>
              <div className={styles.arrowDown}>
                <ArrowDownIcon />
              </div>
            </div>
          }
        </div>
      </div>
      <button
        className={styles.newAccountButton}
        onClick={(ev) => handleClickCreate()}
      >
        CREATE
        </button>
      <Alert
        header={alertState}
        message={`${(alertState === 'Success!')
          ? ` New ${customerOrSite} was created.`
          : " Something went wrong, please try again."
          }`}
        color={`${(alertState === 'Success!') ? "green" : "red"}`}
        isShown={alertState}
        closeAlert={closeAlert}
      />
    </article>
  );
}

export default CreateCustomerOrSite;