import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar from './ProgressBar';
import { addFile } from '../actions/dashboardActions';
import { updateFileLoadProgress, clearFileLoadProgress } from '../actions/uploadFileActions';
import Alert from './Alert';
import { ReactComponent as CloudIcon } from '../assets/cloud.svg';
import { ReactComponent as ArrowDownIcon } from '../assets/arrow-down.svg';
import CRUDService from '../services/CRUDService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { styles } from '../styles';

const UploadFile = ({ folders }) => {
  const dispatch = useDispatch();
  const progress = useSelector(state => state.uploadFileReducer.progress);

  const fileRef = useRef();

  const [folderForFile, setFolderForFile] = useState(folders[0]);
  const [customerRef, setCustomerRef] = useState('');
  const [wfRef, setWfRef] = useState('');
  const [quoteNo, setQuoteNo] = useState('');
  const [dayworkSheetNo, setDayworkSheetNo] = useState('');
  const [jobNo, setJobNo] = useState('');
  const [filename, setFilename] = useState('');
  const [description, setDescription] = useState('');
  const [date, setDate] =  useState(new Date());
  const [selectedFile, setSelectedFile] = useState({});

  const [alertState, setAlertState] = useState('');
  const closeAlert = () => setAlertState(false);

  const setInitilalState = () => {
    setCustomerRef('');
    setWfRef('');
    setQuoteNo('');
    setDayworkSheetNo('');
    setJobNo('');
    setFilename('');
    setDescription('');
    setDate(new Date());
    setSelectedFile({});
  }

  const handleClickUpload = () => {
    if (description && selectedFile.name) {
      CRUDService.postFile({
        customerRef,
        wfRef,
        quoteNo,
        dayworkSheetNo,
        jobNo,
        filename,
        description,
        lastUpdate: (folderForFile.index !== 0 && folderForFile.index !== 6)
          ? date.toISOString() : "",
        file: selectedFile,
        folderId: folderForFile.id,
        handleUploadProgress
      })
        .then(resp => {
          if (resp.status === 201) {
            dispatch(clearFileLoadProgress(progress));
            setAlertState('Success!');
            setInitilalState();
            dispatch(addFile({
              id: resp.data.id,
              customerRef,
              wfRef,
              quoteNo,
              dayworkSheetNo,
              jobNo,
              filename,
              description,
              lastUpdate: (folderForFile.index !== 0 && folderForFile.index !== 6)
                ? date : "",
              size: selectedFile.size,
              folderId: folderForFile.id
            }));
          }
        })
        .catch(error => {
          dispatch(clearFileLoadProgress(progress));
          setAlertState('Failure!');
          setInitilalState();
        })
    }
  }

  const handleUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    const progress = Math.round(loaded / total * 100);
    dispatch(updateFileLoadProgress(progress));
  }

  const renderDefaultLayout = () => (
    <div className='flex flex-row'>
      <div className='flex flex-col items-center mr-6'>
        <label className="mt-3">Choose Folder Import</label>
        <label className="mt-8">File Name</label>
        <label className="mt-9">File Description</label>
        {folderForFile.index === 2 &&
          <label className="mt-9">Last Update</label>
        }
        <label className="mt-20">Browse for File</label>
      </div>
      <div className='flex flex-col items-center'>
        <section className='mt-1 -ml-2'>
          <div className="relative">
            <select
              disabled={!!progress}
              className={styles.uploadFileSelect + `${!!progress ? ' cursor-not-allowed' : ' cursor-pointer'}`}
              value={folderForFile.id}
              onChange={ev => {
                setFolderForFile({ id: Number(ev.target.value), index: folders.find(item => item.id == ev.target.value).index })
              }}
            >
              {folders.map(folder => <option key={folder.id} value={folder.id}>{folder.name}</option>)}
            </select>
            <div className={styles.arrowDown}>
              <ArrowDownIcon />
            </div>
          </div>
        </section>
        <input type="text"
          placeholder='Enter File Name'
          disabled={!!progress}
          className={styles.uploadFileInput + `${!!progress ? ' cursor-not-allowed' : ''}`}
          value={filename}
          onChange={(ev) => setFilename(ev.target.value)}
        />
        <input
          type="text"
          disabled={!!progress}
          placeholder='Enter File Description'
          className={styles.uploadFileInput + `${!!progress ? ' cursor-not-allowed' : ''}`}
          value={description}
          onChange={(ev) => setDescription(ev.target.value)}
        />
        {folderForFile.index === 2 &&
          <DatePicker
            className={styles.uploadFileInput}
            onChange={date => setDate(date)}
            selected={date}
            dateFormat="dd.MM.yyyy"
            maxDate={new Date()}
          />
        }
        <div className='flex flex-col items-center'>
          <div className="flex h-32">
            <label className={styles.selectFileLabel + `${!!progress ? ' cursor-not-allowed' : ''}`}>
              {(selectedFile.name)
                ? <span className="w-64 p-3 break-words overflow-auto">{selectedFile.name}</span>
                : <>
                  <CloudIcon />
                  <span className="mt-2 text-lg leading-normal">Select a file</span>
                  <input
                    ref={fileRef}
                    type='file'
                    disabled={!!progress}
                    className="hidden"
                    onChange={() => setSelectedFile(fileRef.current.files[0])}
                  />
                </>
              }
            </label>
          </div>
        </div>
        {(progress !== 0) &&
          <ProgressBar value={progress} />
        }
      </div>
    </div>
  )

  const renderLayoutForInstructionsOrdersFolder = () => (
    <div className='flex flex-col items-center'>
      <div className='flex flex-row mb-2'>
        <label className="mt-3 mr-4">Choose Folder Import</label>
        <section>
          <div className="relative">
            <select
              disabled={!!progress}
              className={styles.uploadFileSelect + `${!!progress ? ' cursor-not-allowed' : ' cursor-pointer'}`}
              value={folderForFile.id}
              onChange={ev => {
                setFolderForFile({ id: Number(ev.target.value), index: folders.find(item => item.id == ev.target.value).index })
              }}
            >
              {folders.map(folder => 
                <option key={folder.id} value={folder.id} index={folder.index}>{folder.name}</option>
              )}
            </select>
            <div className={styles.arrowDown}>
              <ArrowDownIcon />
            </div>
          </div>
        </section>
      </div>
      <div className='flex flex-row mb-2'>
        <label className="mt-3 mr-4">Customer Ref.</label>
        <input type="text"
          placeholder='Enter Customer Ref.'
          disabled={!!progress}
          className={styles.uploadFileInput + ' mr-10' + `${!!progress ? ' cursor-not-allowed' : ''}`}
          value={customerRef}
          onChange={(ev) => setCustomerRef(ev.target.value)}
        />
        <label className="mt-3 mr-4">WF Ref.</label>
        <input type="text"
          placeholder='Enter WF Ref.'
          disabled={!!progress}
          className={styles.uploadFileInput + `${!!progress ? ' cursor-not-allowed' : ''}`}
          value={wfRef}
          onChange={(ev) => setWfRef(ev.target.value)}
        />
      </div>
      <div className='flex flex-row mb-2 self-start'>
        <label className="mt-3 mr-4">File Description</label>
        <input
          type="text"
          disabled={!!progress}
          placeholder='Enter File Description'
          className={styles.uploadFileInput + `${!!progress ? ' cursor-not-allowed' : ''}`}
          style={{ width: '44rem' }}
          value={description}
          onChange={(ev) => setDescription(ev.target.value)}
        />
      </div>
      <div className='flex flex-row'>
        <label className="mt-3 mr-4">Date Received</label>
        <DatePicker
          className={styles.uploadFileInput}
          onChange={date => setDate(date)}
          selected={date}
          dateFormat="dd.MM.yyyy"
          maxDate={new Date()}
        />
        <label className="mt-3 mr-3 ml-1">Browse for File</label>
        <div className='flex flex-col items-center'>
          <div className="flex h-32">
            <label className={styles.selectFileLabel + `${!!progress ? ' cursor-not-allowed' : ''}`}>
              {(selectedFile.name)
                ? <span className="w-64 p-3 break-words overflow-auto">{selectedFile.name}</span>
                : <>
                  <CloudIcon />
                  <span className="mt-2 text-lg leading-normal">Select a file</span>
                  <input
                    ref={fileRef}
                    type='file'
                    disabled={!!progress}
                    className="hidden"
                    onChange={() => setSelectedFile(fileRef.current.files[0])}
                  />
                </>
              }
            </label>
          </div>
          {(progress !== 0) &&
            <ProgressBar value={progress} />
          }
        </div>
      </div>
    </div>
  )
    
  const renderLayoutForQDDFolders = () => (
    <div className='flex flex-col items-center'>
      <div className='flex flex-row mb-2'>
        <label className="mt-3 mr-4">Choose Folder Import</label>
        <section>
          <div className="relative">
            <select
              disabled={!!progress}
              className={styles.uploadFileSelect + `${!!progress ? ' cursor-not-allowed' : ' cursor-pointer'}`}
              value={folderForFile.id}
              onChange={ev => {
                setFolderForFile({ id: Number(ev.target.value), index: folders.find(item => item.id == ev.target.value).index })
              }}
            >
              {folders.map(folder => 
                <option key={folder.id} value={folder.id} index={folder.index}>{folder.name}</option>
              )}
            </select>
            <div className={styles.arrowDown}>
              <ArrowDownIcon />
            </div>
          </div>
        </section>
      </div>
      <div className='flex flex-row mb-2'>
        <label className="mt-2 mr-4">Customer Ref.</label>
        <input type="text"
          disabled={!!progress}
          className={styles.uploadFileInputForQDD + ' mr-4' + `${!!progress ? ' cursor-not-allowed' : ''}`}
          value={customerRef}
          onChange={(ev) => setCustomerRef(ev.target.value)}
        />
        {folderForFile.index === 3
          ? <label className="mt-2 mr-4">
              Quote No.
            </label>
          : <label className="-mt-3 mr-4">
              <div>Daywork</div>
              <div>Sheet No.</div>
            </label>
        }
        <input type="text"
          disabled={!!progress}
          className={styles.uploadFileInputForQDD + ' mr-4' + `${!!progress ? ' cursor-not-allowed' : ''}`}
          value={folderForFile.index === 3 ? quoteNo : dayworkSheetNo}
          onChange={(ev) => folderForFile.index === 3 
            ? setQuoteNo(ev.target.value) 
            : setDayworkSheetNo(ev.target.value)
          }
        />
        <label className="mt-2 mr-4">Job No.</label>
        <input type="text"
          disabled={!!progress}
          className={styles.uploadFileInputForQDD + `${!!progress ? ' cursor-not-allowed' : ''}`}
          value={jobNo}
          onChange={(ev) => setJobNo(ev.target.value)}
        />
      </div>
      <div className='flex flex-row mb-2 self-start'>
        <label className="mt-3 mr-4">File Description</label>
        <input
          type="text"
          disabled={!!progress}
          placeholder='Enter File Description'
          className={styles.uploadFileInput + `${!!progress ? ' cursor-not-allowed' : ''}`}
          style={{ width: '45rem' }}
          value={description}
          onChange={(ev) => setDescription(ev.target.value)}
        />
      </div>
      <div className='flex flex-row'>
        <label className="mt-3 mr-4">Date Received</label>
        <DatePicker
          className={styles.uploadFileInput}
          onChange={date => setDate(date)}
          selected={date}
          dateFormat="dd.MM.yyyy"
          maxDate={new Date()}
        />
        <label className="mt-3 mr-3 ml-1">Browse for File</label>
        <div className='flex flex-col items-center'>
          <div className="flex h-32">
            <label className={styles.selectFileLabel + `${!!progress ? ' cursor-not-allowed' : ''}`}>
              {(selectedFile.name)
                ? <span className="w-64 p-3 break-words overflow-auto">{selectedFile.name}</span>
                : <>
                  <CloudIcon />
                  <span className="mt-2 text-lg leading-normal">Select a file</span>
                  <input
                    ref={fileRef}
                    type='file'
                    disabled={!!progress}
                    className="hidden"
                    onChange={() => setSelectedFile(fileRef.current.files[0])}
                  />
                </>
              }
            </label>
          </div>
          {(progress !== 0) &&
            <ProgressBar value={progress} />
          }
        </div>
      </div>
    </div>
  )       

  useEffect(() => {
    setFolderForFile(folders[0]);
  }, [folders]) 

  useEffect(() => {
    setInitilalState();
  }, [folderForFile]);

  return (
    <article className="flex flex-col items-center mt-6">
      {folderForFile.index === 1
        ? renderLayoutForInstructionsOrdersFolder()
        : (folderForFile.index === 3 || folderForFile.index === 4 || folderForFile.index === 5)
          ? renderLayoutForQDDFolders()
          : renderDefaultLayout()
      }
      <button
        disabled={!!progress}
        className={styles.uploadFileButton + `${progress ? ' cursor-not-allowed' : ''}`}
        onClick={() => handleClickUpload()}
      >
        UPLOAD
      </button>
      <Alert
        className='mb-3'
        header={alertState}
        message={`${(alertState === 'Success!')
          ? " File was uploaded."
          : " Something went wrong, please try again."
          }`}
        color={`${(alertState === 'Success!') ? "green" : "red"}`}
        isShown={alertState}
        closeAlert={closeAlert}
      />
    </article>
  );
}

export default UploadFile;
