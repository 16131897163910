import { combineReducers } from 'redux';
import { customersReducer } from './reducers/customersReducer';
import { dashboardReducer } from './reducers/dashboardReducer';
import { latestPicturesReducer } from './reducers/latestPicturesReducer';
import { latestFilesReducer } from './reducers/latestFilesReducer';
import { uploadFileReducer } from './reducers/uploadFileReducer';

export const rootReducer = combineReducers({
  customersReducer,
  dashboardReducer,
  latestPicturesReducer,
  latestFilesReducer,
  uploadFileReducer
});