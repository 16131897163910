import React, { useState } from "react";

const DeletionPopup = ({ title, text, name, onDelete, onClose }) => {
  const [input, setInput] = useState("");

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleDeleteClick = () => {
    if (input.toLowerCase().trim() === "delete") {
      onDelete();
      onClose();
    }
  };

  return (
    <div className="flex items-center justify-center fixed left-0 bottom-0 w-full h-full bg-gray-800 bg-opacity-75">
      <div className="bg-white rounded-lg w-1/3 border-2 border-red-500">
        <div className="flex flex-col items-center p-4">
          <div className="flex w-full mb-4 text-bold">
            <span className="text-gray-900 font-medium text-2xl ml-auto mr-auto">
              {title}
            </span>
            <span className="relative">
              <svg
                className="fill-current text-gray-900 w-6 h-6 cursor-pointer absolute top-0 right-0"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 18 18"
                onClick={onClose}
              >
                <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
              </svg>
            </span>
          </div>
          <div className="mb-4">{text}</div>
          <div className="mb-4 text-red-500">{name}</div>
          <div className="mb-4">
            <input
              className="bg-gray-200 border focus:border-gray-500 text-gray-700 rounded py-2 text-center focus:outline-none"
              placeholder="Type Delete here"
              name="input"
              type="text"
              value={input}
              onChange={handleInputChange}
            />
          </div>
          <button
            className="bg-red-500 hover:bg-red-700 text-white py-1 px-8 rounded focus:outline-none"
            onClick={handleDeleteClick}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeletionPopup;
