import React, {useEffect, useState} from "react";

const DeletionItemPopup = (props) => {
    const {openModal, setOpenModal, deleteConfirm, onHandleChangeInput, handleDeleteClick, filesData, targetId} = props
    const [fileDescription, setFileDescription] = useState('')
    useEffect(()=>{
        let currentItem = filesData.find((item) => item.id == targetId)
        if(currentItem) {
            setFileDescription(currentItem.description)
        }
    })
    return (
        <>
        {openModal &&
       <div className='popUpWrapper'>
           <div className='popUp'>
               <div onClick={()=>{setOpenModal(false)}} className='close_button'>+</div>
               <div className='popUp_content'>
                   <h2>Delete Document</h2>
                   <p>Please enter Delete in the below box, to confirm the deletion of the following</p>
                   <p className='text-red-500'>{fileDescription}</p>
                   <input className='input_confirm'
                          type='text'
                          value={deleteConfirm}
                          placeholder='Type Delete Here'
                          onChange={(e)=>{onHandleChangeInput(e.target.value)}}/>
                   <button onClick={()=>{handleDeleteClick(targetId)}}>Delete</button>
               </div>
           </div>
       </div> }
        </>
    );
};

export default DeletionItemPopup;
