import React from 'react';

const ProgressBar = ({ value }) => {
  return (
    <div className="shadow w-64 bg-grey-light mt-3 rounded">
      <div
        className="bg-blue-500 text-xs leading-none py-1 text-center text-white rounded"
        style={{ width: `${value}%` }}
      >
        {value}%
      </div>
    </div>
  );
}

export default ProgressBar;