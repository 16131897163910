import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomers } from '../actions/customersActions';
import withHeader from '../components/withHeader';
import DeletionPopup from '../components/DeletionPopup';
import CRUDService from '../services/CRUDService';
import { formatDate } from '../services/formatDate';
import { getRole } from '../services/getRole';
import { ReactComponent as PenIcon } from '../assets/pen.svg';
import { ReactComponent as TrashIcon } from '../assets/trash.svg';
import { ReactComponent as SortIcon } from '../assets/sort.svg';
import { styles } from '../styles';

function Customers() {
  const dispatch = useDispatch();
  const customers = useSelector(state => state.customersReducer.customers);

  const [search, setSearch] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState(customers);
  const [sortByCustomer, setSortByCustomer] = useState('asc');
  const [sortByLastUpdate, setSortByLastUpdate] = useState('desc');
  const [deletionItem, setDeletionItem] = useState(false);

  const role = getRole();

  const searchCustomer = () => {
    if (search !== '') {
      setFilteredCustomers(customers
        .filter(item => item.name.toLowerCase().includes(search.toLowerCase())))
    }
    else {
      setFilteredCustomers(customers);
    }
  }

  const sort = (sortBy) => {
    if (sortBy === 'customer') {
      setFilteredCustomers(items => {
        if (sortByCustomer === 'asc') {
          setSortByCustomer('desc');
          return items.sort((a, b) => a.name >= b.name ? -1 : 1);
        }
        else {
          setSortByCustomer('asc');
          return items.sort((a, b) => a.name > b.name ? 1 : -1);
        }
      })
    }
    else if (sortBy === 'date') {
      setFilteredCustomers(items => {
        if (sortByLastUpdate === 'asc') {
          setSortByLastUpdate('desc');
          return items.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt));
        }
        else {
          setSortByLastUpdate('asc');
          return items.sort((a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt));
        }
      })
    }
  }

  const handleDeletionPopup = (value) => () => {
    setDeletionItem(value);
  }

  const handleDeleteItem = (id) => () => {
    CRUDService.deleteCustomer(id)
      .then(resp => resp.ok
        ? CRUDService.getCustomers()
            .then(data => {
              dispatch(setCustomers(data));
              setFilteredCustomers(data);
            })
        : null
      )
  }

  useEffect(() => {
    CRUDService.getCustomers()
      .then(data => {
        dispatch(setCustomers(data));
        setFilteredCustomers(data);
      });
  }, []);

  return (
    <article className="flex flex-col items-center">
      <div className="w-4/5 mb-4">
        <input
          type="text"
          className={styles.searchInput}
          placeholder="Search Customer"
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
          onKeyPress={(ev) =>
            ev.key === "Enter" ? searchCustomer(search) : null
          }
        />
        <button
          className={styles.searchButton}
          onClick={(ev) => searchCustomer(search)}
        >
          GO
        </button>
      </div>
      <table className="w-4/5 text-center text-gray-700">
        <thead className="flex w-full">
          <tr className="flex w-full border-solid border-2">
            <th
              className="w-4/12 cursor-pointer flex justify-center items-center"
              onClick={() => sort("customer")}
            >
              <span className="mr-1">Customer</span>
              <SortIcon />
            </th>
            <th
              className="w-4/12 cursor-pointer flex justify-center items-center"
              onClick={() => sort("date")}
            >
              <span className="mr-1">Last Update</span>
              <SortIcon />
            </th>
            <th className={role === 'ROOT' ? "w-2/12" : "w-4/12"}>View</th>
            {role === 'ROOT' 
              && <th className='w-2/12'>Delete</th>
            }
          </tr>
        </thead>
        <tbody
          className="flex flex-col items-center overflow-y-scroll w-full"
          style={{ height: "60vh" }}
        >
          {filteredCustomers.map((item, index) => {
            return (
              <tr
                className="flex w-full my-2 border-solid border-b-2"
                key={index}
              >
                <td className="w-4/12">{item.name}</td>
                <td className="w-4/12">{formatDate(item.updatedAt)}</td>
                <td className={role === 'ROOT' ? "w-2/12" : "w-4/12"} align="center">
                  <Link to={`/customers/${item.id}`}>
                    <PenIcon />
                  </Link>
                </td>
                {role === 'ROOT' &&
                  <td className="w-2/12 cursor-pointer" align="center">
                    <TrashIcon
                      onClick={handleDeletionPopup({
                        customerId: item.id,
                        customerName: item.name,
                      })}
                    />
                  </td>
                }
              </tr>
            );
          })}
        </tbody>
      </table>
      {deletionItem && (
        <DeletionPopup 
          title="Delete Customer"
          text="Please enter Delete in the below box, to confirm the deletion of the following customer:"
          name={deletionItem.customerName}
          onClose={handleDeletionPopup(false)}
          onDelete={handleDeleteItem(deletionItem.customerId)}
        />
      )}
    </article>
  );
}

const wrappedComponent = withHeader(Customers);
export { wrappedComponent as Customers };