import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Alert from '../components/Alert';
import { auth } from '../services/auth';
import { ReactComponent as SignInIcon } from '../assets/sign-in.svg';
import { styles } from '../styles';
import logo from '../assets/logo.png';

function Login() {
  const history = useHistory();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [alertState, setAlertState] = useState(false);
  const closeAlert = () => setAlertState(false);

  const checkAuth = () => {
    auth(username, password)
      .then(data => data.accessToken
        ? history.push('/customers')
        : setAlertState(data.message === 'User is not active'
            ? 'Please contact Warrington Fabrications regarding your account.'
            : 'Incorrect Username or Password.'
          )
      );
  }

  return (
    <article className='h-screen flex flex-col justify-center items-center text-gray-700'>
      <img className="w-56 mb-3 ml-3" src={logo} alt='Logo' />
      <h1 className='text-3xl mb-6'>Secure Cloud Storage Platform</h1>
      <div className="flex">
        <div className="flex flex-col items-center self-end mb-6 mr-3 text-3xl">
          <label className="mb-4" htmlFor="name">Username</label>
          <label className="" htmlFor="password">Password</label>
        </div>
        <div className="flex flex-col items-center">
          <input
            id="name"
            type="text"
            className={styles.loginInput}
            placeholder="Enter Username"
            value={username}
            onChange={(ev) => setUsername(ev.target.value)}
            required
          />
          <input
            id="password"
            type="password"
            className={styles.loginInput}
            pattern="[0-9a-zA-Z]+"
            placeholder="Enter Password"
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
            required
          />
        </div>
      </div>
      <button
        className={styles.loginButton}
        onClick={(ev) => checkAuth(ev)}
      >
        <span className='font-bold mr-2'>LOGIN</span>
        <SignInIcon />
      </button>
      <Alert
        header=''
        message={alertState}
        color='red'
        isShown={alertState}
        closeAlert={closeAlert}
      />
    </article>
  );
}

export { Login };