import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { configureStore } from './store/configureStore';
import { Login } from './pages/Login';
import { SetPassword } from './pages/SetPassword';
import { Customers } from './pages/Customers';
import { Dashboards } from './pages/Dashboards';
import { Dashboard } from './pages/Dashboard';
import { CreateAccount } from './pages/CreateAccount';
import { LatestPictureUploads } from './pages/LatestPictureUploads';
import { LatestFileUploads } from './pages/LatestFileUploads';
import { getRole } from './services/getRole';

const store = configureStore();

const blockRoute = (props) => <Redirect to={props.history.block()} />;

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path='/' render={(props) => {
            if (getRole() === null)
              return <Login {...props} />
            else
              return <Redirect to='/customers' />
          }} />
          <Route exact path='/reset-password' render={(props) => {
            if (getRole() === null)
              return <SetPassword {...props} />
            else
              return <Redirect to='/customers' />
          }} />
          <Route exact path='/customers' component={Customers} />
          <Route exact path='/customers/:customerId' component={Dashboards} />
          <Route exact path='/customers/:customerId/dashboards/:dashboardId' component={Dashboard} />
          <Route exact path='/dashboard/new' render={(props) => {
            return (getRole() === 'ROOT')
              ? <CreateAccount {...props} />
              : blockRoute(props)
          }} />
          <Route exact path='/latest-pictures' render={(props) => {
            return (getRole() === 'ROOT' || getRole() === 'WARRINGTON_EMPLOYEE')
              ? <LatestPictureUploads {...props} />
              : blockRoute(props)
          }} />
          <Route exact path='/latest-files' render={(props) => {
            return (getRole() === 'ROOT')
              ? <LatestFileUploads {...props} />
              : blockRoute(props)
          }} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;