import React, { useState, useEffect } from 'react';
import CRUDService from '../services/CRUDService';
import { styles } from '../styles';

const Permissions = ({ permissions, changePermissions, isAllPermissions, changeIsAllPermissions, customers }) => {
  const [currentCustomer, setCurrentCustomer] = useState(customers[0]?.id || 'no');
  const [currentSite, setCurrentSite] = useState('no');
  const [currentFolders, setCurrentFolders] = useState([]);

  const handleClickAll = () => {
    isAllPermissions
      ? changePermissions([])
      : customers.forEach(customer =>
          customer.sites.forEach(site =>
            CRUDService.getSite(site.id)
              .then(data => data.folders.forEach(folder => changePermissions(prevState =>
                [...prevState, { customerId: customer.id, siteId: site.id, folderId: folder.id }]
              )))
          )
        )
    changeIsAllPermissions(prev => !prev);
  }

  const handleChangeFolders = (folderId) => {
    if (permissions.filter(permission => permission.folderId === folderId).length) {
      changePermissions(prevState =>
        [...prevState.filter(permission => permission.folderId !== folderId)]
      )
    }
    else {
      changePermissions(prevState =>
        [...prevState, { customerId: +currentCustomer, siteId: +currentSite, folderId }]
      )
    }
  }

  useEffect(() => {
    const isCustomerExist = !!customers.length;

    const hasCustomerSite = isCustomerExist && !!customers
      .filter(item => item.id === Number(currentCustomer))[0]?.sites.length;

    if (hasCustomerSite) {
      const newCurrentSite = customers
        .filter(item => item.id === Number(currentCustomer))[0].sites[0]?.id;
      setCurrentSite(newCurrentSite);
    }
    else {
      setCurrentSite('no');
      setCurrentFolders([]);
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (currentSite !== 'no') {
      CRUDService.getSite(currentSite)
        .then(data => {
          const sortedData = data.folders.sort((a, b) => (a.name > b.name) ? 1 : -1);
          setCurrentFolders(sortedData);
        });
    }
  }, [currentSite]);
  
  return (
    <div className="flex flex-col mb-4">
      <p className="font-bold">Permissions</p>
      <div className='flex justify-center'>
        <div>
          <label>
            List of Customers (All <input type='checkbox' checked={isAllPermissions} onChange={() => handleClickAll('customers')} />)
          </label>
          <select
            className={styles.commonSelect + ' cursor-pointer'}
            size='5'
            value={currentCustomer}
            onChange={ev => setCurrentCustomer(ev.target.value)}
          >
            {customers.map(item =>
              <option
                className={`w-full rounded-lg mb-2 px-1 truncate ${permissions
                  .filter(permission => +permission.customerId === item.id).length ? 'bg-yellow-300' : ''
                  }`}
                key={item.id}
                value={item.id}
              >
                {item.name}
              </option>
            )}
          </select>
        </div>
        <div className='mx-4'>
          <label>List of Sites</label>
          <select
            className={styles.commonSelect + ' cursor-pointer'}
            size='5'
            value={currentSite}
            onChange={ev => setCurrentSite(ev.target.value)}
          >
            {customers
              .filter(item => item.id === Number(currentCustomer))[0]?.sites
              ?.map(item =>
                <option
                  className={`w-full rounded-lg my-1 px-1 truncate ${permissions
                    .filter(permission => +permission.siteId === item.id).length ? 'bg-yellow-300' : ''
                    }`}
                  key={item.id}
                  value={item.id}
                >
                  {item.name}
                </option>
              )
            }
          </select>
        </div>
        <div>
          <label>List of Folders</label>
          <div className={"flex flex-col bg-gray-300 border border-gray-200 py-3 px-4 my-2 rounded-lg"}>
            {currentFolders.map(item =>
              <label key={item.id} className="inline-flex items-center">
                <input
                  type="checkbox"
                  style={{}}
                  className="form-checkbox h-5 w-5"
                  onChange={() => handleChangeFolders(item.id)}
                  checked={permissions.filter(permission => permission.folderId === item.id).length}
                />
                <span className="ml-2">{item.name}</span>
              </label>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Permissions;