import React, { useState } from 'react';
import Alert from './Alert';
import Permissions from './Permissions';
import CRUDService from '../services/CRUDService';
import { styles } from '../styles';

function CreateUser({ customers }) {
  const [activeTab, setActiveTab] = useState('WARRINGTON_EMPLOYEE');

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  const [permissions, setPermisiions] = useState([]);
  const [isAllPermissions, setIsAllPermissions] = useState(false);

  const [alertState, setAlertState] = useState('');
  const closeAlert = () => setAlertState(false);

  const setInitilalState = () => {
    setUsername('');
    setEmail('');
    setPermisiions([]);
    setIsAllPermissions(false);
  }
  const handleClickCreate = () => {
    if (username && email) {
      CRUDService.postEmployeeWithPermissions({
        username,
        email,
        password: '111',
        role: activeTab,
        permissions
      })
        .then(data => {
          if (!data.statusCode) {
            setAlertState('Success!');
            setInitilalState();
          }
          else {
            setAlertState('Failure!');
            setInitilalState();
          }
        })
        .catch(error => {
          setAlertState('Failure!');
          setInitilalState();
        })
    }
  }

  return (
    <article className="self-start flex flex-col items-center w-full">
      <nav className="flex flex-col sm:flex-row rounded-lg mb-4">
        <button
          onClick={() => {
            setActiveTab('WARRINGTON_EMPLOYEE');
            setInitilalState();
          }}
          className={`py-4 px-6 rounded-l-lg focus:outline-none 
            ${activeTab === 'WARRINGTON_EMPLOYEE' && 'bg-yellow-500'}`}
        >
          Create Warrington Fabrications Employee Account
        </button>
        <button
          onClick={() => {
            setActiveTab('CUSTOMER_EMPLOYEE');
            setInitilalState();
          }}
          className={`py-4 px-6 rounded-r-lg focus:outline-none
            ${activeTab === 'CUSTOMER_EMPLOYEE' && 'bg-yellow-500'}`}
        >
          Create Customer Employee Account
        </button>
      </nav>
      <div className='flex justify-center'>
        <label className="mr-4">Username
          <input
            id="username"
            type="text"
            placeholder='Enter Username'
            className={styles.newAccountInput}
            value={username}
            onChange={(ev) => setUsername(ev.target.value)}
          />
        </label>
        <label>Email
          <input
            id="email"
            type="email"
            placeholder='Enter Email'
            className={styles.newAccountInput + ' w-80'}
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
          />
        </label>
      </div>
      <Permissions
        customers={customers}
        permissions={permissions}
        changePermissions={(value) => setPermisiions(value)}
        isAllPermissions={isAllPermissions}
        changeIsAllPermissions={(value) => setIsAllPermissions(value)}
      />
      <button
        className={styles.newAccountButton + ' mr-7'}
        onClick={() => handleClickCreate()}
      >
        CREATE
        </button>
      <Alert
        header={alertState}
        message={`${(alertState === 'Success!')
          ? " New account was created."
          : " Something went wrong, please try again."
          }`}
        color={`${(alertState === 'Success!') ? "green" : "red"}`}
        isShown={alertState}
        closeAlert={closeAlert}
      />
    </article>
  );
}

export default CreateUser;