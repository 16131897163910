import Cookies from 'js-cookie';
import { BASE_URL } from '../constants';

export const auth = (username, password) => {
  return fetch(BASE_URL + '/auth/login', {
    method: 'POST',
    body: JSON.stringify({ username, password }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(resp => resp.json())
    .then(data => {
      if (data.accessToken) {
        Cookies.set('token', {
          accessToken: data.accessToken,
          expires: data.expiresIn,
          role: data.user.role
        })
      }
      return data;
    })
    .catch(error => error);
}