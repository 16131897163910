import { SET_DASHBOARD, ADD_FILE, EDIT_FILE, DELETE_FILE } from './actionTypes';

export const setDashboard = (data) => {
  return {
    type: SET_DASHBOARD,
    payload: data
  }
}

export const addFile = (data) => {
  return {
    type: ADD_FILE,
    payload: data
  }
}

export const editFile = (data) => {
  return {
    type: EDIT_FILE,
    payload: data
  }
}

export const deleteFile = (id) => {
  return {
    type: DELETE_FILE,
    payload: id
  }
}