import { SET_CUSTOMERS, ADD_NEW_CUSTOMER, ADD_SITE_TO_CUSTOMER } from './actionTypes';

export const setCustomers = (data) => {
  return {
    type: SET_CUSTOMERS,
    payload: data
  }
}

export const addNewCustomer = (data) => {
  return {
    type: ADD_NEW_CUSTOMER,
    payload: data
  }
}

export const addSiteToCustomer = (data) => {
  return {
    type: ADD_SITE_TO_CUSTOMER,
    payload: data
  }
}