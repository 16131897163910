import React from 'react';
import { formatDate } from '../services/formatDate';
import { ReactComponent as FolderIcon } from '../assets/folder.svg';

function Folders({ data, changeActiveFolder }) {
  return (
    <>
      <table className='w-11/12 text-center text-gray-700'>
        <thead>
          <tr className='border-solid border-2'>
            <th></th>
            <th>Folders Name</th>
            <th>Size</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr className='border-solid border-b-2' key={index}>
                <td align='center' className='cursor-pointer' folder-id={item.id} folder-index={item.index} onClick={(ev) => {
                  changeActiveFolder(
                    Number(ev.currentTarget.getAttribute('folder-id')),
                    Number(ev.currentTarget.getAttribute('folder-index'))
                  );
                }}
                >
                  <FolderIcon />
                </td>
                <td className='cursor-pointer' folder-id={item.id} folder-index={item.index} onClick={(ev) => {
                  changeActiveFolder(
                    Number(ev.currentTarget.getAttribute('folder-id')),
                    Number(ev.currentTarget.getAttribute('folder-index'))
                  );
                }}
                >
                  {item.name}
                </td>
                <td>{Math.round(item.size / 1000)} kb</td>
                <td>{formatDate(item.updatedAt)}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </>
  )
}

export default Folders;