import { SET_DASHBOARD, ADD_FILE, EDIT_FILE, DELETE_FILE, CLEAR_STATE } from '../actions/actionTypes';

const initialState = {
  folders: []
}

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DASHBOARD: {
      return { ...state, folders: action.payload };
    }
    case ADD_FILE: {
      const { 
        id, 
        customerRef, 
        wfRef, 
        quoteNo,
        dayworkSheetNo,
        jobNo,
        filename: name, 
        description, 
        lastUpdate, 
        size, 
        folderId 
      } = { ...action.payload };
      const updatedFolders = [...state.folders];

      updatedFolders.forEach((folder, index) => {
        if (folder.id === folderId) {
          updatedFolders[index].size += size
          updatedFolders[index].updatedAt = new Date();
          updatedFolders[index].files.unshift({ 
            id, 
            customerRef, 
            wfRef,
            quoteNo,
            dayworkSheetNo,
            jobNo,
            name, 
            description, 
            lastUpdate, 
            size, 
            updatedAt: new Date() 
          });
        }
      });

      return { ...state, folders: updatedFolders };
    }
    case EDIT_FILE: {
      const { 
        folderId, 
        fileId, 
        wfRef, 
        customerRef, 
        quoteNo,
        dayworkSheetNo,
        jobNo,
        name, 
        description,
      } = { ...action.payload };
      const updatedFolders = [...state.folders];

      updatedFolders.forEach((folder, index) => {
        if (folder.id === folderId) {
          updatedFolders[index].updatedAt = new Date();
          updatedFolders[index].files.forEach(file => {
            if (file.id === fileId) {
              file.wfRef = wfRef;
              file.customerRef = customerRef;
              file.quoteNo = quoteNo;
              file.dayworkSheetNo = dayworkSheetNo;
              file.jobNo = jobNo;
              file.name = name;
              file.description = description;
              file.updatedAt = new Date();
            }
          })
        }
      });

      return { ...state, folders: updatedFolders };
    }
    case DELETE_FILE: {
      const { folderId, fileId } = { ...action.payload };
      const updatedFolders = [...state.folders];

      const updatedFiles = [...state.folders]
        .filter(folder => folder.id === folderId)[0].files
        .filter(file => file.id !== +fileId);

      updatedFolders.forEach((folder, index) => {
        if (folder.id === folderId) {
          updatedFolders[index].updatedAt = new Date();
          updatedFolders[index].files = updatedFiles;
          updatedFolders[index].size = updatedFiles.reduce((prev, cur) => prev + cur.size, 0);
        }
      });

      return { ...state, folders: updatedFolders };
    }
    case CLEAR_STATE: {
      return { ...initialState };
    }
    default:
      return state;
  }
}