import React, { useState, useEffect } from 'react';
import Alert from './Alert';
import Permissions from './Permissions';
import CRUDService from '../services/CRUDService';
import { ReactComponent as ArrowDownIcon } from '../assets/arrow-down.svg';
import { styles } from '../styles';

function EditUser({ customers }) {
  const [emlpoyees, setEmlpoyees] = useState([]);
  const [currentEmployee, setCurrentEmployee] = useState('');
  const [active, setActive] = useState();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const [permissions, setPermissions] = useState([]);
  const changePermissions = (newPermissions) => setPermissions(newPermissions);

  const [alertState, setAlertState] = useState('');
  const closeAlert = () => setAlertState(false);

  const handleClickEdit = () => {
    const currentEmployeeData = emlpoyees.find(item => item.id === currentEmployee);

    if (password.trim() === passwordConfirm.trim()) {
      CRUDService.updateEmployeeWithPermissions(
        currentEmployeeData.id, {
          username: currentEmployeeData.username,
          email: currentEmployeeData.email,
          role: currentEmployeeData.role,
          active,
          password,
          permissions
      })
        .then(data => {
          if (!data.statusCode) {
            setAlertState('Success!');
            setPassword('');
            setPasswordConfirm('');
          }
          else {
            setAlertState('Failure!');
          }
        })
        .catch(error => {
          setAlertState('Failure!');
        })
    }
  }

  useEffect(() => {
    CRUDService.getUsers('EMPLOYEE')
      .then(data => {
        setEmlpoyees(data);
      });
  }, []);

  useEffect(() => {
    if (emlpoyees.length) {
      setCurrentEmployee(emlpoyees[0].id);
    }
  }, [emlpoyees]);

  useEffect(() => {
    if (currentEmployee) {
      CRUDService.getPermissions(currentEmployee)
        .then(data => {
          setPermissions(data.permissions);
          setActive(data.active);
        });
    }
  }, [currentEmployee]);

  return (
    <article className="flex flex-col items-center w-full mb-12">
      <div className='flex'>
        <label className="mr-4 self-center">Users</label>
        <div className='relative'>
          <select
            className={styles.commonSelect + ' w-80 truncate cursor-pointer'}
            value={currentEmployee}
            onChange={ev => setCurrentEmployee(Number(ev.target.value))}
          >
            {emlpoyees.map(item => <option key={item.id} value={item.id}>{item.username}</option>)}
          </select>
          <div className={styles.arrowDown}>
            <ArrowDownIcon />
          </div>
        </div>
      </div>
      <div className='flex items-center mt-4'>
        <label className='mr-8'>
          <span className='mr-2'>
            Active
          </span>
          <input
            className='w-4 h-4'
            type='checkbox'
            checked={active}
            onChange={() => setActive(prevState => !prevState)}
          />
        </label>
        <label className='mr-2'>
          Reset Password
        </label>
        <input
          id="password"
          type="password"
          placeholder='Enter Password'
          className={styles.editAccountInput}
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
        />
        <input
          id="passwordConfirm"
          type="password"
          placeholder='Re-Enter Password'
          className={styles.editAccountInput}
          value={passwordConfirm}
          onChange={(ev) => setPasswordConfirm(ev.target.value)}
        />
      </div>
      <Permissions
        customers={customers}
        permissions={permissions}
        changePermissions={changePermissions}
      />
      <button
        className={styles.newAccountButton + ' mr-7'}
        onClick={() => handleClickEdit()}
      >
        Update
      </button>
      <Alert
        header={alertState}
        message={`${(alertState === 'Success!')
          ? " Employee permissions were updated."
          : " Something went wrong, please try again."
          }`}
        color={`${(alertState === 'Success!') ? "green" : "red"}`}
        isShown={alertState}
        closeAlert={closeAlert}
      />
    </article>
  );
}

export default EditUser;
