import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Alert from '../components/Alert';
import { resetPassword } from '../services/resetPassword';
import { ReactComponent as SignInIcon } from '../assets/sign-in.svg';
import { styles } from '../styles';
import logo from '../assets/logo.png';

function SetPassword() {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const history = useHistory();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [alertState, setAlertState] = useState(false);
  const closeAlert = () => setAlertState(false);

  const handleSendClick = () => {
    if (password === confirmPassword) {
      resetPassword({ password, token: query.get('token') })
        .then(data => (data === null)
          ? setAlertState('Something went wrong, please try again.')
          : history.push('/customers')
        );
    }
    else {
      setAlertState('Password mismatch, please try again.')
    }
  }

  return (
    <article className='h-screen flex flex-col justify-center items-center text-gray-700'>
      <img className="w-56 mb-3 ml-3" src={logo} alt='Logo' />
      <h1 className='text-3xl mb-6'>Secure Cloud Storage Platform</h1>
      <div className="flex">
        <div className="flex flex-col items-center self-end mb-6 mr-3 text-3xl">
          <label className="mb-4" htmlFor="name">Password</label>
          <label className="" htmlFor="password">Confirm Password</label>
        </div>
        <div className="flex flex-col items-center">
          <input
            id="password"
            type="password"
            className={styles.loginInput}
            pattern="[0-9a-zA-Z]+"
            value={password}
            onChange={(ev) => setPassword(ev.target.value)}
            required
          />
          <input
            id="name"
            type="password"
            className={styles.loginInput}
            value={confirmPassword}
            onChange={(ev) => setConfirmPassword(ev.target.value)}
            required
          />
        </div>
      </div>
      <button
        className={styles.loginButton}
        onClick={() => handleSendClick()}
      >
        <span className='font-bold mr-2'>SEND</span>
        <SignInIcon />
      </button>
      <Alert
        header=''
        message={alertState}
        color='red'
        isShown={alertState}
        closeAlert={closeAlert}
      />
    </article>
  );
}

export { SetPassword };