import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import withHeader from '../components/withHeader';
import DeletionPopup from '../components/DeletionPopup';
import { formatDate } from '../services/formatDate';
import { getRole } from '../services/getRole';
import { ReactComponent as PenIcon } from '../assets/pen.svg';
import { ReactComponent as TrashIcon } from '../assets/trash.svg';
import { ReactComponent as SortIcon } from '../assets/sort.svg';
import CRUDService from '../services/CRUDService';

const Dashboards = () => {
  const { customerId } = useParams();

  const [customer, setCustomer] = useState({});
  const [sites, setSites] = useState();
  const [sortBySites, setSortBySites] = useState('asc');
  const [sortByLastUpdate, setSortByLastUpdate] = useState('desc');
  const [deletionItem, setDeletionItem] = useState(false);

  const role = getRole();

  const sort = (sortBy) => {
    if (sortBy === 'site') {
      setSites(items => {
        if (sortBySites === 'asc') {
          setSortBySites('desc');
          return items.sort((a, b) => a.name >= b.name ? -1 : 1);
        }
        else {
          setSortBySites('asc');
          return items.sort((a, b) => a.name > b.name ? 1 : -1);
        }
      })
    }
    else if (sortBy === 'date') {
      setSites(items => {
        if (sortByLastUpdate === 'asc') {
          setSortByLastUpdate('desc');
          return items.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt));
        }
        else {
          setSortByLastUpdate('asc');
          return items.sort((a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt));
        }
      })
    }
  }

  const handleDeletionPopup = (value) => () => {
    setDeletionItem(value);
  }

  const handleDeleteItem = (id) => () => {
    CRUDService.deleteSite(id)
      .then(resp => resp.ok
        ? CRUDService.getCustomer(customerId)
            .then(data => setCustomer(data))
        : null
      )
  }

  useEffect(() => {
    CRUDService.getCustomer(customerId)
      .then(data => setCustomer(data));
  }, []);

  useEffect(() => {
    setSites(customer.sites);
  }, [customer]);

  return (
    <article className="flex flex-col items-center text-gray-700">
      {customer && <h2 className='w-4/5 mb-3 text-3xl'>{customer.name}</h2>}
      <table className='w-4/5 text-center text-gray-700'>
        <thead className='flex w-full'>
          <tr className='flex w-full border-solid border-2'>
            <th className='w-4/12 cursor-pointer flex justify-center items-center'
              onClick={() => sort('site')}
            >
              <span className='mr-1'>Site Name</span>
              <SortIcon />
            </th>
            <th className='w-4/12 cursor-pointer flex justify-center items-center'
              onClick={() => sort('date')}
            >
              <span className='mr-1'>Last Update</span>
              <SortIcon />
            </th>
            <th className={role === 'ROOT' ? "w-2/12" : "w-4/12"}>Edit</th>
            {role === 'ROOT' 
              && <th className='w-2/12'>Delete</th>
            }
          </tr>
        </thead>
        <tbody
          className='flex flex-col items-center overflow-y-scroll w-full'
          style={{ height: '60vh' }}
        >
          {sites && sites.map((item, index) => {
            return (
              <tr className='flex w-full my-2 border-solid border-b-2' key={index}>
                <td className='w-4/12'>{item.name}</td>
                <td className='w-4/12'>{formatDate(item.updatedAt)}</td>
                <td className={role === 'ROOT' ? "w-2/12" : "w-4/12"} align='center'>
                  <Link to={`/customers/${customerId}/dashboards/${item.id}`}>
                    <PenIcon />
                  </Link>
                </td>
                {role === 'ROOT' &&
                  <td className='w-2/12 cursor-pointer' align='center'>
                    <TrashIcon
                      onClick={handleDeletionPopup({
                        customerId: item.id,
                        customerName: item.name,
                      })}
                    />
                  </td>
                }
              </tr>
            )
          })}
        </tbody>
      </table>
      {deletionItem && (
        <DeletionPopup 
          title="Delete Site"
          text="Please enter Delete in the below box, to confirm the deletion of the following site:"
          name={deletionItem.customerName}
          onClose={handleDeletionPopup(false)}
          onDelete={handleDeleteItem(deletionItem.customerId)}
        />
      )}
    </article >
  );
}

const wrappedComponent = withHeader(Dashboards);
export { wrappedComponent as Dashboards };