import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveLatestFiles } from '../actions/latestFilesActions';
import withHeader from '../components/withHeader';
import CRUDService from '../services/CRUDService';
import { getToken } from '../services/getToken';
import { formatDate } from '../services/formatDate';
import { ReactComponent as FileIcon } from '../assets/file.svg';
import { ReactComponent as SortIcon } from '../assets/sort.svg';
import { ReactComponent as DownloadIcon } from '../assets/download.svg';
import { styles } from '../styles';
import { BASE_URL } from '../constants';

function LatestFileUploads() {
  const dispatch = useDispatch();
  const latestFiles = useSelector(state => state.latestFilesReducer.latestFiles);

  const token = getToken();

  const [search, setSearch] = useState('');
  const [sortByCustomer, setSortByCustomer] = useState('asc');
  const [sortByDate, setSortByDate] = useState('desc');
  const [filteredFiles, setFilteredFiles] = useState(latestFiles);

  const searchFile = () => {
    if (search !== '') {
      setFilteredFiles(latestFiles
        .filter(item => item.name.toLowerCase().includes(search.toLowerCase())
          || item.description.toLowerCase().includes(search.toLowerCase())
        )
      )
    }
    else {
      setFilteredFiles(latestFiles);
    }
  }

  const sort = (sortBy) => {
    if (sortBy === 'customer') {
      setFilteredFiles(files => {
        if (sortByCustomer === 'asc') {
          setSortByCustomer('desc');
          return files.sort((a, b) => a.folder.customer.name >= b.folder.customer.name ? -1 : 1)
        }
        else {
          setSortByCustomer('asc');
          return files.sort((a, b) => a.folder.customer.name > b.folder.customer.name ? 1 : -1)
        }
      })
    }
    else if (sortBy === 'date') {
      setFilteredFiles(files => {
        if (sortByDate === 'asc') {
          setSortByDate('desc');
          return files.sort((a, b) => Date.parse(b.updatedAt) - Date.parse(a.updatedAt));
        }
        else {
          setSortByDate('asc');
          return files.sort((a, b) => Date.parse(a.updatedAt) - Date.parse(b.updatedAt));
        }
      })
    }
  }

  useEffect(() => {
    CRUDService.getLastFiles('all')
      .then(data => {
        dispatch(saveLatestFiles(data));
        setFilteredFiles(data);
      })
  }, []);

  return (
    <article className='flex flex-col items-center'>
      <div className='w-11/12 mb-4'>
        <input
          type="text"
          className={styles.searchInput}
          placeholder="Search Files"
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
          onKeyPress={(ev) => (ev.key === 'Enter') ? searchFile(search) : null}
        />
        <button
          className={styles.searchButton}
          onClick={(ev) => searchFile(search)}
        >
          GO
        </button>
      </div>
      <table className='w-11/12 text-center text-gray-700'>
        <thead className='flex w-full'>
          <tr className='flex w-full border-solid border-2'>
            <th className='w-1/20'></th>
            <th className='w-3/20'>File Name</th>
            <th className='w-4/20'>File Description</th>
            <th className='w-3/20 cursor-pointer flex justify-center items-center'
              onClick={() => sort('customer')}
            >
              <span className='mr-1'>Customer</span>
              <SortIcon />
            </th>
            <th className='w-3/20'>Uploaded By</th>
            <th className='w-2/20'>Size</th>
            <th className='w-3/20 cursor-pointer flex justify-center items-center'
              onClick={() => sort('date')}
            >
              <span className='mr-1'>Date</span>
              <SortIcon />
            </th>
            <th className='w-1/20'></th>
          </tr>
        </thead>
        <tbody className='flex flex-col items-center overflow-y-scroll w-full' style={{ height: '60vh' }}>
          {filteredFiles.map((item, index) => {
            return (
              <tr className='flex items-center w-full my-2 border-solid border-b-2' file-id={item.id} key={index}>
                <td className='w-1/20' align='center'><FileIcon /></td>
                <td className='w-3/20 cursor-pointer overflow-x-hidden' onClick={(ev) => {
                  CRUDService.getFile(ev.currentTarget.parentNode.getAttribute('file-id'));
                }}>
                  {item.name}
                </td>
                <td className='w-4/20 overflow-x-hidden'>{item.description}</td>
                <td className='w-3/20 overflow-x-hidden'>{item.folder.customer.name}</td>
                <td className='w-3/20'>{item.uploadedBy.username}</td>
                <td className='w-2/20'>{Math.round(item.size / 1000)} kb</td>
                <td className='w-3/20'>{formatDate(item.updatedAt)}</td>
                <td className='w-1/20 px-4 cursor-pointer' align='center'>
                  <a name={item.name} href={`${BASE_URL}/file/${item.id}?token=${token}`} download>
                    <DownloadIcon />
                  </a>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </article>
  );
}

const wrappedComponent = withHeader(LatestFileUploads);
export { wrappedComponent as LatestFileUploads };